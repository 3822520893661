import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { DashboardWrapper, SentryErrorButton } from "./Styles";
import { Posts } from "../../Components/Tabs/Posts/Posts";
//@ts-ignore
import IntroCard from "../../Components/Dashboard/IntroCard/IntroCard";
import {
  useGetProfileQuery,
  useLazyGetAllPostsQuery,
} from "../../../redux/reducers/apiSlice";
import { NUMBER_OF_LOGINS_BEFORE_HIDING_INTRO } from "../../utils/constants";
import SuggestedForYouMobile from "../../Components/SuggestedForYouMobile/SuggestedForYouMobile";
import useScreenType from "react-screentype-hook";

const Dashboard = () => {
  const [
    getAllPostsQuery,
    { data: pagePosts, isLoading: postsLoading, isError: isPostsError },
  ] = useLazyGetAllPostsQuery();

  const { data: currentProfile } = useGetProfileQuery();
  const [page, setPage] = useState(1);
  const [allPosts, setAllPosts] = useState(pagePosts || []);
  const { isMobile, isTablet } = useScreenType({   
    mobile: 400,
    tablet: 1346,
    desktop: 1000,
    largeDesktop: 1600
  });

  const [keysPressed, setKeysPressed] = useState({
    E: false,
    R: false,
    O: false,
  });
  const [showSentryErrorButton, setShowSentryErrorButton] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const { key } = event;

      if (key === "e" || key === "E") {
        setKeysPressed((prev) => ({ ...prev, E: true }));
      } else if (key === "r" || key === "R") {
        setKeysPressed((prev) => ({ ...prev, R: true }));
      } else if (key === "o" || key === "O") {
        setKeysPressed((prev) => ({ ...prev, O: true }));
      }

      // Check if all keys are pressed
      if (keysPressed.E && keysPressed.R && keysPressed.O) {
        setShowSentryErrorButton(true);
      }
    };

    const handleKeyUp = (event) => {
      const { key } = event;

      if (key === "e" || key === "E") {
        setKeysPressed((prev) => ({ ...prev, E: false }));
      } else if (key === "r" || key === "R") {
        setKeysPressed((prev) => ({ ...prev, R: false }));
      } else if (key === "o" || key === "O") {
        setKeysPressed((prev) => ({ ...prev, O: false }));
      }

      // Hide the button if any key is released
      setShowSentryErrorButton(false);
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [keysPressed]);

  useEffect(() => {
    getAllPostsQuery({ limit: 5, page });
  }, [page]);

  useEffect(() => {
    if (pagePosts?.length && pagePosts?.length > 0) {
      if (page === 1) {
        setAllPosts(pagePosts);
      } else {
        setAllPosts([...allPosts, ...pagePosts]);
      }
    }
  }, [pagePosts]);

  useEffect(() => {
    if (isPostsError) {
      toast.error(
        "Something went wrong while fetching the posts. Try again later"
      );
    }
  }, [isPostsError]);

  const onResetPosts = () => {
    setPage(1);
  };

  const hasIntroCard = () => {
    const loginFlag = localStorage.getItem(
      `${currentProfile?.email}-loginFlag`
    );
    if (loginFlag) {
      return parseInt(loginFlag) <= NUMBER_OF_LOGINS_BEFORE_HIDING_INTRO;
    }
    return false;
  };

  return (
    <DashboardWrapper hasIntroCard={hasIntroCard()}>
      {showSentryErrorButton && (
        // @ts-ignore
        <SentryErrorButton onClick={() => methodDoNotExists()}>
          Create sentry error
        </SentryErrorButton>
      )}
      {hasIntroCard() && <IntroCard />}
      {(isMobile || isTablet) && (
        <div
          style={{
            marginTop: hasIntroCard() ? "32px" : "24px",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
        >
          <SuggestedForYouMobile />
        </div>
      )}
      <Posts
        hasIntroCard={hasIntroCard()}
        showPostCreationForm={true}
        posts={allPosts}
        postsLoading={postsLoading}
        disableLoadMore={postsLoading}
        onLoadMore={() => {
          let currentPage = page;
          currentPage++;
          setPage(currentPage);
        }}
        resetPage={onResetPosts}
      />
    </DashboardWrapper>
  );
};

export default Dashboard;
