import { useState, useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import {
  useGetProfileQuery,
  useLazyGetPostGroupQuery,
} from "../../redux/reducers/apiSlice";
import { useLazyGetOrganizationDetailsQuery, useLazyGetOrganizationProfileQuery } from "../../redux/reducers/apiSlice";
import { MESSAGE } from "../Constants/Message";
import * as Sentry from "@sentry/react";
import { useSelector } from "react-redux";
import { tokenSelector } from "../../redux/reducers/authSlice";

export const useOrganization = () => {
  const { organizationId, sparkName } = useParams();
  const [currentUserRole, setCurrentUserRole] = useState<"MEMBER" | "ADMIN">(
    "MEMBER"
  );
  const { data: currentUserProfile, isLoading: currentUserProfileLoading } =
    useGetProfileQuery();
  const [getOrganizationDetails, { isLoading, data: organizationData }] =
    useLazyGetOrganizationDetailsQuery();
  const [getOrganizationProfile, { isLoading: isOrganizationProfileLoading, data: organizationProfileData }] =
  useLazyGetOrganizationProfileQuery();

  const isAuthenticated = useSelector(tokenSelector)

  const [
    getOrganizationPostGroup,
    { data: posts, isLoading: organizationPostsLoading, isError: isPostsError },
  ] = useLazyGetPostGroupQuery();

  const myOrganizations = currentUserProfile?.organizations || [];

  useEffect(() => {
    if (organizationId) {
      getOrganizationDetailsFn();
      if(isAuthenticated){
        getOrganizationPostGroup(organizationId);
      }
    }
  }, [organizationId, isAuthenticated]);

  const getOrganizationDetailsFn = async () => {
    try {
      if (organizationId) {
        const response = await isAuthenticated ?  getOrganizationDetails(organizationId).unwrap() : getOrganizationProfile(organizationId).unwrap();
        const orgData = response;
        const adminId = currentUserProfile?.id;
        const role = orgData?.members?.find(
          (element) => element.id === adminId
        )?.role;
        setCurrentUserRole(role || "MEMBER");
      }
    } catch (e) {
      Sentry.captureException(e);
      toast.error(MESSAGE.SYSTEM_ERROR);
    }
  };

  const orgMembers = useMemo(() => {
    return organizationData?.members || [];
  }, [organizationData?.id]);

  const admins = useMemo(() => {
    return organizationData?.members?.filter(
      (member) => member.role === "ADMIN"
    );
  }, [organizationData?.id]);

  const getRole = (memberId: string) => {
    return orgMembers.find((member) => member.id === memberId)?.role;
  };

  const goalsBySparkName = useMemo(() => {
    return (
      organizationData?.goals?.filter(
        (goal) => goal.spark.name === sparkName
      ) || []
    );
  }, [sparkName, organizationData?.goals]);

  const isCurrentUserAdmin = useMemo(() => {
    if (admins && admins.length > 0) {
      return admins.find((admin) => admin?.id === currentUserProfile?.id);
    }

    return false;
  }, [admins]);

  const isCurrentUserAMember = useMemo(() => {
    if (orgMembers && orgMembers.length > 0) {
      return orgMembers.find((admin) => admin?.id === currentUserProfile?.id);
    }

    return false;
  }, [orgMembers]);

  return {
    currentUserRole,
    currentOrganization: organizationData || organizationProfileData,
    currentOrganizationLoading: isLoading || isOrganizationProfileLoading,
    members: orgMembers,
    refetch: getOrganizationDetailsFn,
    getRole,
    goalsBySparkName,
    organizationPosts: posts,
    organizationPostsLoading,
    myOrganizations,
    myOrganizationsLoading: currentUserProfileLoading,
    admins,
    isCurrentUserAdmin,
    isCurrentUserAMember,
  };
};
