import { useEffect, useState, useRef, useCallback  } from "react";
import * as _ from 'lodash';
import { SelectInstance } from 'react-select';
import toast from 'react-hot-toast';
import { City, State, DebouncedAndMemoizedGetCitiesRequestType, SetDropdownOptionsParamsType } from '../../redux/reducers/types';
import { useLazyGetCitiesQuery, useGetStateQuery } from "../../redux/reducers/apiSlice";
import { customConsole } from "../utils/helper";

const PAGE_LIMIT = 10;

const useCitiesAndStates = ({ formik }) => {

  const [stateDropdownOptions, setStateDropdownOptions] = useState<{ label: string; value: string}[]>();
  const [citiesDropdownOptions, setCitiesDropdownOptions] = useState<{ label: string; value: string}[]>([]);
  const { data: states, isLoading: isStatesLoading, error: statesError } = useGetStateQuery();
  const [getCities, { error: citiesError }] =  useLazyGetCitiesQuery();
  const [cityInputValue, setCityInputValue] = useState("");
  const [selectedStateIsoCode, setSelectedStateIsoCode] = useState(null);
  const citiesSelectRef =  useRef<SelectInstance>(null)
  const [isCitiesMenuOpen, setIsCitiesMenuOpen] = useState(false);
  const [citiesDataOffset, setCitiesDataOffset] = useState(0);
  const [isLoadingCities,setIsLoadingCities] = useState(false);

  const setDropdownOptions = ({ data, type, oldOptions = [] }: SetDropdownOptionsParamsType) => {
    const isCity = type === "City";
    const dropdownOptions =  data.map((item: City | State) => ({
      label: item.name,
      value: item.name,
      isoCode: !isCity ? (item as State).isoCode  : undefined
    }));

    if(isCity) {
      setCitiesDropdownOptions([...oldOptions, ...dropdownOptions]);
    } else {
      setStateDropdownOptions(dropdownOptions)
    }
  }

  useEffect(() => {
    if(states){
      setDropdownOptions({ data: states, type: "State"})
    }
  },[states])

  const debouncedAndMemoizedGetCitiesRequest = useCallback(_.debounce(async ({ state, offset, namePrefix, dropdownOptions = [] }: DebouncedAndMemoizedGetCitiesRequestType) => {
    try {
      setIsLoadingCities(true);
      const citiesResponse = await getCities({
        limit: PAGE_LIMIT,
        offset, 
        state, 
        namePrefix
      })
      setCitiesDataOffset(offset);
      setIsLoadingCities(false);
      setDropdownOptions({ data: citiesResponse.data?.cities, oldOptions: dropdownOptions, type: "City"  });
    }catch(e){
      customConsole("error", "FetchCitiesError", e)
      toast.error("Something went wrong while fetching the list of cities. Try selecting the state again");
    }
  
 },1000) ,[]) // adheres to the 1 request per second limit on Rapid API

  useEffect(() => {

    if(citiesError){
      // @ts-ignore
      customConsole("log",citiesError?.data?.message)
    }

    if(statesError){
      // @ts-ignore
      customConsole("log",citiesError?.data?.message)
    }
  // @ts-ignore
  },[citiesError?.data?.message, statesError?.data?.message])


  const onStateChange = async (option) => {
    if(citiesSelectRef?.current){
      citiesSelectRef?.current.focus();
    }

    formik.setFieldValue("location.state", option.value);
    setSelectedStateIsoCode(option.isoCode);
    setCitiesDataOffset(0);

    try {
      debouncedAndMemoizedGetCitiesRequest({  state: option.isoCode, offset: 0 })
    } catch(e){
       toast.error("Something went wrong while fetching the list of cities. Try selecting the state again");
    }
}

const onCityInputChange = (city: string) =>  {
  setCityInputValue(city)
  if(selectedStateIsoCode && city !== ""){
    debouncedAndMemoizedGetCitiesRequest({ state: selectedStateIsoCode, offset: 0, namePrefix: city })
  }else {
    debouncedAndMemoizedGetCitiesRequest({ state: selectedStateIsoCode, offset: 0 })
  }
}

const onMenuScrollToBottom = () => {
    const newOffset = citiesDataOffset + PAGE_LIMIT;
    if(selectedStateIsoCode){
      const dropdownOptions = newOffset > 0 ? [...citiesDropdownOptions] : []
      debouncedAndMemoizedGetCitiesRequest({ state: selectedStateIsoCode, offset: newOffset, dropdownOptions })
    }
}

 return {
     stateDropdownOptions, 
     isStatesLoading,
     selectedStateIsoCode,
     onCityInputChange,
     citiesDropdownOptions, 
     cityInputValue,
     citiesSelectRef, 
     citiesDataOffset, 
     isLoadingCities,
     isCitiesMenuOpen, 
     setIsCitiesMenuOpen,
     onStateChange,
     onMenuScrollToBottom
 }
}


export default useCitiesAndStates;