import { Box, FormLabel, Spinner } from '@chakra-ui/react';
import { RequiredAsterisk } from '../Modal/Styles';
import { SelectV2 } from '../../Reusable';
import * as _ from 'lodash';
import useCitiesAndStates from '../../hooks/useCitiesAndStates';

const CitiesAndStates = ({ formik }) => {

  const { 
    cityInputValue, 
    setIsCitiesMenuOpen,  
    onCityInputChange, 
    onMenuScrollToBottom, 
    citiesDropdownOptions, 
    stateDropdownOptions, 
    isCitiesMenuOpen, 
    isLoadingCities, 
    citiesSelectRef, 
    isStatesLoading,
     onStateChange 
    } = useCitiesAndStates({ formik })


  return (
    <>
      <Box mb="32px" style={{ position: "relative", zIndex: 999 }}>
        <FormLabel>
          State <RequiredAsterisk />
        </FormLabel>
        <SelectV2
            options={stateDropdownOptions}
            placeholder="State"
            name="location.state"
            isLoading={isStatesLoading}
            value={formik.values.location.state}
            onChange={onStateChange}
          />
      </Box>
      <Box mb="32px" style={{ position: "relative", zIndex: 998 }}>
        <FormLabel>
          City <RequiredAsterisk /> {isLoadingCities && <span style={{marginLeft: '8px'}}><Spinner color="black" size={"sm"} /></span>}
        </FormLabel>     
        <SelectV2
          innerRef={citiesSelectRef}
          menuIsOpen={isCitiesMenuOpen}
          inputValue={cityInputValue}
          placeholder="City"
          name="location.city"
          options={citiesDropdownOptions}
          value={formik.values.location.city}
          onChange={(option) => formik.setFieldValue("location.city", option.value)}
          onInputChange={onCityInputChange}
          onMenuScrollToBottom={onMenuScrollToBottom}
          onFocus={() => setIsCitiesMenuOpen(true)}
          onBlur={() => setIsCitiesMenuOpen(false)}
        />
      </Box>
    </>
  )
}

export default CitiesAndStates;