import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import { Form, Formik } from "formik";
import {
  Box,
  Button,
  Text,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import {
  MESSAGE,
  MinMaxMessage,
  RegexNoWhiteSpace,
} from "../../Constants/Message";
import { InputV2 } from "../../Reusable";
import { Email, Eye, Password } from "../../Assets/SvgIcons";
import { SignUpWrapper } from "../SignUp/Styles";
import { LoginFormData } from "../../../types";
import { CHALLENGES } from "../../utils/cognito";
import { Policy } from "../../Components/Policy/Policy";
import * as Sentry from "@sentry/react";
import { useDispatch } from "react-redux";
import { setToken } from "../../../redux/reducers/authSlice";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const signIn = async (data: LoginFormData) => {
    try {
      setLoading(true);
      const user = await Auth.signIn(data.email, data.password);
      dispatch(setToken({ token: user.signInUserSession.idToken.jwtToken}))
      setLoading(false);

      localStorage.setItem("hasShownSurvey", "false");
      const attributes = user.signInUserSession?.idToken?.payload;
      const storageKey = `${attributes.email}-loginFlag`;
      const loginFlagValue = localStorage.getItem(storageKey);
      if (loginFlagValue) {
        const newLoginFlagValue = parseInt(loginFlagValue) + 1;
        localStorage.setItem(storageKey, newLoginFlagValue.toString());
      } else {
        localStorage.setItem(storageKey, "1");
      }

      if (user.challengeName === CHALLENGES.NEW_PASSWORD_REQUIRED) {
        navigate("/new-password-required", {
          state: {
            email: data.email,
          },
        });
      } else {
        navigate("");
      }
    } catch (error) {
      Sentry.captureException(error);
      setLoading(false);
      if (error.code === "UserNotConfirmedException") {
        toast.remove();
        toast.error("User is not confirmed");
        await Auth.resendSignUp(data.email);
        navigate("/confirm-signup", {
          state: {
            email: data.email,
            password: data.password,
          },
        });
      } else {
        toast.remove();
        toast.error("Username or Password is not correct");
      }
    }
  };

  const yupValidate = Yup.object({
    email: Yup.string()
      .matches(RegexNoWhiteSpace, {
        message: MESSAGE.WHITE_SPACE,
        excludeEmptyString: true,
      })
      .email(MESSAGE.EMAIL_INVALID)
      .required(MESSAGE.INPUT_REQUIRED)
      .max(100, MinMaxMessage("max", "Password", "100")),
    password: Yup.string()
      .min(8, MinMaxMessage("min", "Password", "8"))
      .max(30, MinMaxMessage("max", "Password", "30"))
      .required(MESSAGE.INPUT_REQUIRED),
  });

  return (
    <SignUpWrapper>
      <div className="col-logo">
        <img
          src="/images/logo-2.png"
          alt="Measure"
          className="img-logo mx-auto"
        />
      </div>

      <div className="col-form">
        <div className="box-wrapper mx-auto" style={{ maxWidth: 410 }}>
          <img
            src="/images/logo-3.png"
            alt="Measure"
            className="img-logo mx-auto"
          />
          {/* <SocialSignUp />

          <div className="box-divider mx-auto">
            <span>Or</span>
          </div> */}
        </div>

        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={yupValidate}
          onSubmit={async (data, handlers) => {
            handlers.setSubmitting(true);
            handlers.validateForm(data);
            await signIn(data);
            // handlers.resetForm();
            handlers.setSubmitting(false);
          }}
        >
          {(formik) => {
            return (
              <Form>
                <div className="box-wrapper mx-auto" style={{ maxWidth: 410 }}>
                  <Box mb="24px">
                    <InputV2
                      name="email"
                      placeholder="Email Address"
                      inputLeftElement={
                        <InputLeftElement
                          pointerEvents="none"
                          children={<Email />}
                        />
                      }
                      restInput={{ maxLength: 100 }}
                    />
                  </Box>

                  <Box mb="16px">
                    <InputV2
                      name="password"
                      placeholder="Password"
                      inputLeftElement={
                        <InputLeftElement
                          pointerEvents="none"
                          children={<Password />}
                        />
                      }
                      inputRightElement={
                        <InputRightElement
                          children={<Eye />}
                          pointerEvents="initial"
                          className="cursor-pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      }
                      restInput={{
                        type: showPassword ? "text" : "password",
                        maxLength: 30,
                      }}
                    />
                  </Box>

                  <Box mb="32px">
                    <Text
                      as={Link}
                      to="/forgot-password"
                      fontSize={16}
                      fontWeight="semibold"
                      _hover={{ color: "primary.600" }}
                    >
                      Forgot Password?
                    </Text>
                  </Box>

                  <Box mb="16px">
                    <Button
                      type="submit"
                      colorScheme="primary"
                      isLoading={loading}
                      loadingText="Sign in"
                      className="w-100"
                    >
                      Sign in
                    </Button>
                  </Box>
                  {/* @ts-ignore */}
                  <Text
                    fontSize={16}
                    fontWeight="semibold"
                    color="#000"
                    textAlign="center"
                    padding="6px 0"
                  >
                    Not a Member?{" "}
                    <Text
                      as={Link}
                      to="/signup"
                      fontSize={16}
                      fontWeight="semibold"
                      _hover={{ color: "primary.600" }}
                    >
                      Join now
                    </Text>
                  </Text>
                </div>
                <Policy type="footer" />
              </Form>
            );
          }}
        </Formik>
      </div>
    </SignUpWrapper>
  );
};

export default Login;
