import { Field } from "formik";
import Select, { GroupBase, MenuListProps, components } from "react-select";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
} from "@chakra-ui/react";
import {
  getNestedFormikValue,
  isNestedFormikValuePresent,
} from "../../utils/formErrors";

import composeRefs from "@seznam/compose-react-refs";
import { useCallback, useEffect, useMemo, useRef } from "react";
import type { ComponentType, ReactElement } from "react";


export const CHECK_TIMEOUT = 300;

export type BaseSelectProps = {
	handleScrolledToBottom?: () => void;
};

type MenuListType<
	Option = unknown,
	IsMulti extends boolean = boolean,
	Group extends GroupBase<Option> = GroupBase<Option>,
> = ComponentType<MenuListProps<Option, IsMulti, Group>>;

export function wrapMenuList<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(MenuList: MenuListType<Option, IsMulti, Group>) {
  function WrappedMenuList(
    props: MenuListProps<Option, IsMulti, Group>
  ): ReactElement {
    const { selectProps, innerRef } = props;
    const { handleScrolledToBottom } = selectProps as unknown as BaseSelectProps;

    const menuListRef = useRef<HTMLDivElement>(null);

    const handleScroll = useCallback(() => {
      const el = menuListRef.current;

      if (el) {
        const { scrollTop, scrollHeight, clientHeight } = el;

        // Check if the user has scrolled to the bottom
        if (scrollHeight - scrollTop === clientHeight) {
          if (handleScrolledToBottom) {
            handleScrolledToBottom();
          }
        }
      }
    }, [handleScrolledToBottom]);

    useEffect(() => {
      const el = menuListRef.current;

      if (el) {
        el.addEventListener("scroll", handleScroll);

        return () => {
          el.removeEventListener("scroll", handleScroll);
        };
      }
    }, [handleScroll]);

    return (
      <MenuList
        {...props}
        innerRef={composeRefs<HTMLDivElement>(innerRef, menuListRef)}
      />
    );
  }

  return WrappedMenuList;
}

type SelectOption = {
  label: string;
  value: string;
}

const CustomMenuList = wrapMenuList(components.MenuList);


export const SelectV2 = ({
  name,
  title,
  value,
  inputValue,
  options,
  placeholder,
  inputLeftElement,
  inputRightElement,
  restSelect,
  onChange,
  onInputChange,
  onMenuScrollToBottom,
  disabled,
  onFocus, 
  onBlur, 
  menuIsOpen,
  hideError,
  innerRef,
  isLoading,
  ...rest
}: any) => {
  /**
   * Default value
   */
  const defaultValue = (options, value) => {
    let localOptions;

    if (value && Array.isArray(value)) {
      localOptions = options
        ? options.filter((option) => value.includes(option.value))
        : "";
    } else {
      localOptions = options
        ? options.find((option) => option.value === value)
        : "";
    }

    return localOptions;
  };

  return (
    <Field name={name} {...rest}>
      {({ field, form }) => (
        <FormControl
          isInvalid={
            isNestedFormikValuePresent(form.errors, name) &&
            isNestedFormikValuePresent(form.errors, name)
          }
          style={{ zIndex: "inherit" }}
        >
          {title && <FormLabel htmlFor={name}>{title}</FormLabel>}

          <InputGroup _disabled={disabled}>
            {inputLeftElement}
            <Select     
              isDisabled={disabled}
              ref={innerRef}
              value={defaultValue(options, value)}
              inputValue={inputValue}
              onInputChange={onInputChange}
              options={options}
              placeholder={placeholder || ""}
              className="react-select-container"
              classNamePrefix="react-select"
              menuIsOpen={menuIsOpen}
              isLoading={isLoading}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              {...restSelect}
              handleScrolledToBottom= {onMenuScrollToBottom}
              components = {
                {
                  MenuList: CustomMenuList
                }
              }
            />
            {inputRightElement}
          </InputGroup>

          {!hideError && (
            <FormErrorMessage>
              {getNestedFormikValue(form.errors, name)}
            </FormErrorMessage>
          )}
        </FormControl>
      )}
    </Field>
  );
};
