
import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: { token: null},
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload.token;
    },
    clearToken: (state) => {
      state.token = null;
    }
  }})

export const { setToken, clearToken } = authSlice.actions;

export const tokenSelector = (state: { auth: { token: string; } }) => state.auth.token;

export default authSlice.reducer;

