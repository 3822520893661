import styled from "styled-components";
import { Inbox } from "@talkjs/react";
import { devices } from "../../Theme.css";

export const MessagesInboxWrapper = styled(Inbox)`
  height: 800px;
  padding-top: 15px;
  padding-left: 20px;

  .MessageList {
    height: 334px;
  }
    
  @media ${devices.max_sm} {
    padding-left: 0;
    height: 85vh;
  }
`;

