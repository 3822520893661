import styled from "styled-components";
import { Avatar } from "@chakra-ui/react";
import { devices } from "../../../Theme.css";
import { vars } from "../../../Theme.css";
import { Link } from "react-router-dom";

export const FollowButtonSpacer = styled.div`
  @media ${devices.max_sm} {
    margin-top: 12px;
  }
`;

export const OrganizationProfileWrapper = styled.div`
  margin-bottom: 24px;
  .care-certified-text {
    margin-left: 12px;
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .care-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 32px;
  }

  .anti-racist-statement {
    padding: 12px 44px;
    color: var(--black, #000);
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 176%; /* 24.64px */
  }

  .square-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @media only screen and (max-width: 1353px) {
      .anti-racist-statement {
        margin-top: 24px;
      }
    }
  }

  .tabs-container {
    margin-top: 51px;
  }
`;

type OrganizationProfileTopCardProps = {
  introCardPresent?: boolean;
};

export const OrganizationProfileTopCard = styled.div<OrganizationProfileTopCardProps>`
  min-height: 273px;
  background: #ffffff;
  box-shadow: 1px 1px 6px rgba(64, 26, 15, 0.25);
  ${(props) =>
    props.introCardPresent
      ? `border-radius: 40px; margin-top: 32px;`
      : `border-radius: 0px 0px 40px 40px;`}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: calc(136.5px + 23px);

  h1,
  h2 {
    color: #000;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  h2 {
    font-size: 20px;
  }

  .label {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140.5%; /* 19.67px */
  }

  .value {
    color: #000;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .top-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 44px;
    flex: 1;
  }

  .bottom-row {
    max-height: 130.5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 44px;
  }

  .bottom-row-column {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 44px;
    @media screen and (max-width: 1123px) and (min-width: 769px) {
      flex-direction: column;
    }
  }

  .row-group-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .following-container {
    margin-left: 44px;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 32px;
  }
  @media ${devices.max_sm} {
    display: none;
  }
`;

export const OrganizationProfilePicture = styled.div`
  width: 270px;
  height: 270px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  /* margin-top: -130.5px; */
  overflow: hidden;
  transform: translateY(50%);

  img: {
    display: block;
    width: 100%;
  }

  /** Add media query to tackle images between 1041 and 768px */
  @media screen and (max-width: 1161px) and (min-width: 769px) {
    width: 100px;
    height: 100px;
  }
`;

export const ProfileAvatarStyles = styled(Avatar)`
  width: 270px;
  height: 270px;
  @media screen and (max-width: 1040px) and (min-width: 769px) {
    width: 100px;
    height: 100px;
  }
`;

export const OrganizationCarePictureContainer = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #d9d9d9;
  overflow: hidden;

  img {
    width: 100%;
    display: block;
  }
`;

export const SecondaryButton = styled.button`
  border: 1px solid rgb(230, 71, 18);
  color: rgb(230, 71, 18);
  border-radius: 100px;
  padding: 8px 16px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140.5%; /* 22.48px */
`;

export const SquareCard = styled.div`
  max-width: 70%;
  width: fit-content;
  max-height: 95px;
  border-radius: 30px;
  background: #fff;
  box-shadow: 1px 1px 6px 0px rgba(64, 26, 15, 0.25);

  position: relative;

  .anti-racist-statement-text {
    overflow: scroll;
    max-height: 100%;
  }

  .pencil-container {
    position: absolute;
    right: 10px;
  }

  .icons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .icon-container {
    margin-top: -5px;
    margin-left: 44px;
    width: 44px;
    height: 44px;

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .icon-container:last-child {
    margin-right: 44px;
  }

  @media ${devices.max_sm} {
    display: none;
  }
`;

export const OrganizationSparks = styled.div<{ isEditable?: boolean }>`
  max-width: 70%;
  width: fit-content;
  height: 95px;
  border-radius: 30px;
  background: #fff;
  box-shadow: 1px 1px 6px 0px rgba(64, 26, 15, 0.25);

  .sparks-pencil-container {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 10px;
    padding-top: 10px;
  }

  .icons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    ${(props) => !props.isEditable && `height: 100%;`}
  }

  .icon-container {
    margin-top: -5px;
    margin-left: 44px;
    width: 44px;
    height: 44px;

    svg {
      display: none;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .icon-container:last-child {
    margin-right: 44px;
  }

  @media ${devices.max_sm} {
    display: none;
  }
`;

/** Dashboard styles */

export const DashboardPage = styled.div`
  .intro-card {
    margin-bottom: 62px;
    @media ${devices.max_md} {
      padding-top: 30px;
      margin-bottom: 40px;
    }
  }
  .box-dashboard-main {
    display: flex;
    margin-top: 4rem;
    flex-wrap: nowrap;
    .community-card-wrapper {
      flex: 0 0 auto;
      width: calc(100% - 345px);
      padding-right: 122px;
      min-width: 655px;
    }
    .box-dashboard-sidebar {
      flex: 0 0 auto;
      width: 345px;
    }
  }
`;

export const CommunityCardWrapper = styled.div`
  margin-bottom: 48px;
`;

export const CommunityPost = styled.div`
  margin: 0 auto 0 auto;
  background: #fff;
  box-shadow: 1px 1px 6px rgba(64, 26, 15, 0.25);
  border-radius: 40px;
  padding: 24px;
  min-width: 555px;
  max-width: calc(100% - 150px);
  .box-textarea {
    display: flex;
    flex-wrap: nowrap;
    gap: 14px;
    .box-avatar {
      display: flex;
      align-items: center;
      // flex: 1 1 14px;
    }
    .box-caption {
      width: 100%;
      border-radius: 30px;
      color: #000;
      background: #eee;
      border-color: #eee;
      box-shadow: none;
      .chakra-textarea {
        flex: 1 1 calc(100% - 14px);
        resize: none;
        &:hover,
        &:focus {
          resize: vertical;
        }
      }
      .text-post-caption {
        overflow: hidden;
        max-height: 100px;
        margin-left: 20px;
        background: none;
        width: calc(100% - 20px);
        resize: none;
        padding-right: 0;
        padding-left: 0;
        margin-bottom: 5px;
        margin-top: 5px;
        border-radius: 0;
      }
    }
  }
`;

export const BoxPreviewLink = styled.div`
  position: relative;
  margin-top: 24px;
  display: flex;
  width: 100%;
  border-radius: 30px;
  .btn-close-preview {
    position: absolute;
    right: 14px;
    top: 14px;
    height: 25px;
    width: 25px;
    background: white;
    text-align: center;
    z-index: 999;
    border-radius: 50%;
    line-height: 25px;
    opacity: 0.8;
  }
  .react_tinylink_card {
    width: 100%;
    border-radius: 30px;
    max-width: 100%;
  }
`;

export const SuggestionTitleHeader = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 700;
`;

export const ImageButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 20px;
  padding: 10px 12px;
  :hover {
    background: #f4f7ff;
  }
`;

export const CommunityImage = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 50px;
  justify-content: flex-end;
  .__text {
    margin-left: 4px;
  }
`;

export const LoadMoreButton = styled.div`
  margin: 10px;
  color: ${vars.color.buttonBlue};
  cursor: pointer;
  opacity: 0.7;
  :hover {
    opacity: 1;
  }
`;

export const TabSection = styled.div`
  background: #ffffff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 40px;
  min-height: 150px;
  @media ${devices.max_sm} {
    margin-top: 10px;
    min-width: 0;
    max-width: 100%;
  }
  padding: 30px;
  min-width: 335px;
  max-width: 360px;
  max-height: 800px;
`;

// Mobile Layout

export const OrganizationMobileTopCard = styled.div`
  padding: 24px 24px 16px 24px;
  border-bottom: 1px solid #eee;
  display: none;
  height: fit-content;
  @media ${devices.max_sm} {
    display: block;
  }
`;

export const OrganizationMobileTopCardTopLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  .followers-info {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
`;

export const OrganizationMobileTopPicture = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
  }
`;

export const OrganizationMobileGoals = styled.div`
  width: 100%;
  padding: 8px 32px;
  border: 1px solid #d1d1d1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 12px;
  .icon-container {
    margin-right: 32px;
    width: 24px;
    height: 24px;
  }
  .icon-container:last-child {
    margin-right: 0px;
  }
`;

export const OrganizationMobileName = styled.h2`
  color: #000;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: 140.5%; /* 16.86px */
`;

export const OrganizationMobileType = styled.p``;

export const OrganizationMobileFollowIngCount = styled.div`
  text-align: center;
`;

export const OrganizationMobileFollowerCount = styled(
  OrganizationMobileFollowIngCount
)``;

export const OrganizationMobileSecondaryButton = styled.button`
  /* width: max-content; */
  width: max-content;
  border: 1px solid rgb(230, 71, 18);
  color: rgb(230, 71, 18);
  border-radius: 100px;
  padding: 8px 16px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140.5%;
  text-align: center;
  margin-top: 12px;
`;

export const MobileButtonLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  button {
    margin-right: 24px;
  }
`;

export const BackButtonWrapper = styled.div`
  display: block;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  width: 100%;
  margin-top: 24px;
  /* margin-bottom: 24px; */
  @media ${devices.max_sm} {
    display: none;
  }
`;

export const Header = styled.div`
  padding: 24px;
  box-shadow: 0px 10px 10px rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
`;

export const QRCodeWrapper = styled.div`
    display: flex;
  justify-content: center; 
  align-items: center;
  margin-top: 8px;

`;

export const Message = styled.p`
  margin-right: 8px;
`

export const PageWrapper = styled.div<{ isAuthenticated: boolean }>`
  
  padding: ${props => !props.isAuthenticated ? "24px" : "0px" };

  @media ${devices.max_sm}{
    padding: 0px;
  }

`;