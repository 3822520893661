import { useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  SimpleGrid,
  Input,
  CheckboxGroup,
  Checkbox,
  Textarea,
  FormLabel,
} from "@chakra-ui/react";
import { ErrorMessage, ErrorSpacer, RequiredAsterisk } from "./styles";
import { InputV2, SelectV2 } from "../../../Reusable";
import RadioButtonGroup from "../../../Components/RadioButtonGroup/RadioButtonGroup";
import CitiesAndStates from '../../../Components/CitiesAndStates/CitiesAndStates';

const StepGeneralInfo = ({ formik, editMode }) => {

const type = formik.values.type;

  return (
    <div className="step-general-info">
      <Box mb="32px">
        <Text fontSize="11px" color="#000" mb="12px">
          All fields marked with ( <RequiredAsterisk /> ) are required
        </Text>
        <SimpleGrid columns={{ sm: 1, md: 2 }} spacingX="44px">
          <Box mb="32px">
            <FormLabel>
              Organization name <RequiredAsterisk />
            </FormLabel>
            <InputV2
              name="name"
              value={formik.values.name}
              restInput={{ maxLength: 150 }}
              inputRightElement={<RequiredAsterisk />}
            />
          </Box>
          {editMode && (
            <Box mb="32px" style={{ position: "relative", zIndex: 40000 }}>
              <FormLabel>
                Organization type <RequiredAsterisk />
              </FormLabel>
              <SelectV2
                placeholder="Type"
                name="type"
                options={[
                  { label: "Grassroots", value: "grassroots" },
                  { label: "Non Profit", value: "non-profit" },
                  { label: "Foundation", value: "foundation" },
                  { label: "Corporation", value: "corporation" },
                ]}
                value={formik.values.type}
                onChange={(option) =>
                  formik.setFieldValue("type", option.value)
                }
              />
            </Box>
          )}
          <Box mb="32px">
            <FormLabel>
              Phone number <RequiredAsterisk />
            </FormLabel>
            <InputV2
              name="phoneNumber"
              restInput={{ maxLength: 150 }}
              value={formik.values.phoneNumber}
            />
          </Box>
        </SimpleGrid>
      </Box>

      <Box mb="32px">
        <SimpleGrid columns={{ sm: 1, md: 2 }} spacingX="44px">
          <Box mb="32px">
            <FormLabel>
              Address <RequiredAsterisk />
            </FormLabel>
            <InputV2
              placeholder="Street number and name"
              name="location.address1"
              restInput={{ maxLength: 150 }}
              value={formik.values.location.address1}
            />
          </Box>
          <CitiesAndStates formik={formik} />
          <Box mb="32px">
            <FormLabel>
              Zip code <RequiredAsterisk />
            </FormLabel>
            <InputV2
              placeholder="Zip code"
              name="location.zip"
              restInput={{ maxLength: 150 }}
              value={formik.values.location.zip}
            />
          </Box>
        </SimpleGrid>
      </Box>
      <SimpleGrid columns={[2]} spacingX="44px">
        {(type === "grassroots" || type === "non-profit") && (
          <Box mb="32px">
            <Text fontSize="14px" fontWeight="semibold" color="#000" mb="12px">
              Are you Measure Care Model certified?
            </Text>
            <RadioButtonGroup
              formik={formik}
              name="orgFields.careModelCertified"
              options={[
                { label: "Yes", value: "true" },
                { label: "No", value: "false" },
              ]}
            />
          </Box>
        )}
        {(type === "non-profit" || type === "foundation") && (
          <Box mb="32px">
            <FormLabel>
              EIN Number <RequiredAsterisk />
            </FormLabel>
            <InputV2
              placeholder={"EIN Number"}
              name="orgFields.ein"
              restInput={{ maxLength: 150 }}
            />
          </Box>
        )}
      </SimpleGrid>
      <Box mb="32px">
        <Text fontSize="14px" fontWeight="semibold" color="#000" mb="12px">
          Who leads your organization? Check all that apply.{" "}
          <RequiredAsterisk />
        </Text>

        <CheckboxGroup
          value={formik.values.labels}
          onChange={(val) => {
            formik.setFieldValue("labels", val);
          }}
        >
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacingX="44px">
            <Box>
              <Box mb="10px">
                <Checkbox
                  key="bipoc-led"
                  value="bipoc-led"
                  colorScheme="primary"
                >
                  BIPOC-led
                </Checkbox>
              </Box>

              <Box mb="10px">
                <Checkbox
                  key="powerful-black-led"
                  value="powerful-black-led"
                  colorScheme="primary"
                >
                  Powerful Black-Led
                </Checkbox>
              </Box>

              <Box mb="10px">
                <Checkbox
                  key="powerful-brown-led"
                  value="powerful-brown-led"
                  colorScheme="primary"
                >
                  Powerful Brown-Led
                </Checkbox>
              </Box>

              <Box mb="10px">
                <Checkbox
                  key="native-american-or-alaskan-american"
                  value="native-american-or-alaskan-american"
                  colorScheme="primary"
                >
                  Powerful Indigenous-Led
                </Checkbox>
              </Box>
            </Box>
            <Box>
              <Box mb="10px">
                <Checkbox key="woman" value="woman-led" colorScheme="primary">
                  Woman Led
                </Checkbox>
              </Box>
              <Box mb="10px">
                <Checkbox key="lgbtq" value="lgbtq-led" colorScheme="primary">
                  LGBTQ-Led
                </Checkbox>
              </Box>

              <Box mb="10px" className="not-listed-field">
                <Flex minWidth="max-content" alignItems="center" gap="2">
                  <Box>
                    <Checkbox
                      key="not-listed"
                      value="not-listed"
                      colorScheme="primary"
                    >
                      Other:
                    </Checkbox>
                  </Box>

                  <Box flexGrow={1}>
                    <Input
                      isDisabled={
                        !formik.values?.labels?.includes("not-listed")
                      }
                      value={formik.values.labelNotListed}
                      onChange={(val) =>
                        formik.setFieldValue("labelNotListed", val.target.value)
                      }
                      className="flushed"
                      {...{ maxLength: 50 }}
                      name="labelNotListed"
                    />
                  </Box>
                </Flex>
              </Box>
            </Box>
            <ErrorSpacer>
              <ErrorMessage>{formik.errors["labelNotListed"]}</ErrorMessage>
            </ErrorSpacer>
          </SimpleGrid>
        </CheckboxGroup>
        <Box marginBlockStart={12}>
          <FormLabel>Mission</FormLabel>
          <Textarea
            placeholder="Write your mission..."
            name="mission"
            height={110}
            onChange={(option) =>
              formik.setFieldValue("mission", option.target.value)
            }
          />
          <ErrorSpacer>
            <ErrorMessage>{formik.errors["mission"]}</ErrorMessage>
          </ErrorSpacer>
        </Box>
        <Box marginBlockStart={12}>
          <FormLabel>Problem statement</FormLabel>
          <Textarea
            placeholder="Write the problems you are working solve..."
            name="problem"
            onChange={(option) => {
              formik.setFieldValue("problem", option.target.value);
            }}
            height={110}
            value={formik.values.problem}
          />
          <ErrorSpacer>
            <ErrorMessage>{formik.errors["problem"]}</ErrorMessage>
          </ErrorSpacer>
        </Box>
        <Box marginBlockStart={12}>
          <FormLabel>Vision</FormLabel>
          <Textarea
            name="vision"
            value={formik.values.vision}
            onChange={(option) => {
              formik.setFieldValue("vision", option.target.value);
            }}
            placeholder="write your vision here..."
            height={110}
          />
          <ErrorSpacer>
            <ErrorMessage>{formik.errors["vision"]}</ErrorMessage>
          </ErrorSpacer>
        </Box>
        <Box marginBlockStart={12}>
          <FormLabel>Anti-racist statement</FormLabel>
          <Textarea
            name="anti-racist"
            value={formik.values.orgFields.antiRacist}
            onChange={(option) => {
              formik.setFieldValue("orgFields.antiRacist", option.target.value);
            }}
            placeholder="Write your anti-racist statement here..."
            height={110}
          />
          <ErrorSpacer>
            <ErrorMessage>{formik.errors.orgFields?.antiRacist}</ErrorMessage>
          </ErrorSpacer>
        </Box>

        <Box marginBlockStart={12}>
          <Text fontSize="14px" fontWeight="semibold" color="#000" mb="12px">
            What communities do you serve? Check all that apply.{" "}
            <RequiredAsterisk />
          </Text>

          <CheckboxGroup
            onChange={(val) => {
              formik.setFieldValue("communitiesServed", val);
            }}
            value={formik.values.communitiesServed}
          >
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacingX="44px">
              <Box>
                <Box mb="10px">
                  <Checkbox
                    key="powerful-black-community"
                    value="powerful-black-community"
                    colorScheme="primary"
                  >
                    Powerful Black Community
                  </Checkbox>
                </Box>

                <Box mb="10px">
                  <Checkbox
                    key="powerful-brown-community"
                    value="powerful-brown-community"
                    colorScheme="primary"
                  >
                    Powerful Brown Community
                  </Checkbox>
                </Box>

                <Box mb="10px">
                  <Checkbox
                    key="powerful-indigenous-community"
                    value="powerful-indigenous-community"
                    colorScheme="primary"
                  >
                    Powerful Indigenous community
                  </Checkbox>
                </Box>
              </Box>
              <Box>
                <Box mb="10px">
                  <Checkbox key="woman" value="woman" colorScheme="primary">
                    Woman
                  </Checkbox>
                </Box>
                <Box mb="10px">
                  <Checkbox key="lbgtq" value="lgbtq" colorScheme="primary">
                    LGBTQ
                  </Checkbox>
                </Box>
                <Box mb="10px" className="not-listed-field">
                  <Flex minWidth="max-content" alignItems="center" gap="2">
                    <Box>
                      <Checkbox
                        key="not-listed"
                        value="not-listed"
                        colorScheme="primary"
                      >
                        Other:
                      </Checkbox>
                    </Box>

                    <Box flexGrow={1}>
                      <Input
                        isDisabled={
                          !formik.values?.communitiesServed?.includes(
                            "not-listed"
                          )
                        }
                        value={formik.values.communityServedNotListed}
                        onChange={(val) =>
                          formik.setFieldValue(
                            "communityServedNotListed",
                            val.target.value
                          )
                        }
                        className="flushed"
                        {...{ maxLength: 50 }}
                        required
                      />
                    </Box>
                  </Flex>
                </Box>
              </Box>
              <ErrorSpacer>
                <ErrorMessage>
                  {formik.errors["communityServedNotListed"]}
                </ErrorMessage>
              </ErrorSpacer>
            </SimpleGrid>
          </CheckboxGroup>
        </Box>
      </Box>
    </div>
  );
};

export default StepGeneralInfo;
