import { Box, Text, SimpleGrid, Spinner } from "@chakra-ui/react";
import { InputV2, SelectV2 } from "../../../Reusable";
import RadioButtonGroup from "../../../Components/RadioButtonGroup/RadioButtonGroup";
import { signupRadioButtons } from "../../../utils/helper";
import CitiesAndStates from "../../../Components/CitiesAndStates/CitiesAndStates";

const StepGeneralInfo = ({ formik }) => {


  return (
    <div className="step-general-info">
      <SimpleGrid columns={{ sm: 1, md: 2 }} spacingX="44px">
        <Box mb="32px">
          <InputV2
            placeholder="First name"
            name="name.firstName"
            restInput={{ maxLength: 150 }}
            value={formik.values.name.firstName}
          />
        </Box>
        <Box mb="32px">
          <InputV2
            placeholder="Last name"
            name="name.lastName"
            restInput={{ maxLength: 150 }}
            value={formik.values.name.lastName}
          />
        </Box>
        <Box mb="32px">
          <InputV2
            placeholder="Headline (CEO of or Change maker)"
            name="title"
            restInput={{ maxLength: 150 }}
            value={formik.values.title}
          />
        </Box>
       <CitiesAndStates formik={formik} />
      </SimpleGrid>
      {signupRadioButtons.map(({ label, name, options, notListedField }) => (
        <Box key={label} mb="32px">
          <Text fontSize="14px" fontWeight="semibold" color="#000" mb="12px">
            {label}
          </Text>
          <RadioButtonGroup
            name={name}
            options={options}
            formik={formik}
            notListedFieldName={notListedField}
          />
        </Box>
      ))}
    </div>
  );
};

export default StepGeneralInfo;
