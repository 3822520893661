import { useMemo, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { MESSAGE } from "../../Constants/Message";
import { BackButtonWrapper, OrganizationDetailsPageWrapper } from "./Styles";
import { OrganizationDetailsCard } from "../../Components/OrganizationDetailsCard/OrganizationDetailsCard";
import { Tabs } from "../../Components/Tabs/Tabs";
import { Modal } from "../../Components/Modal/Modal";
import { Members } from "../../Components/Tabs/Members/Members";
import { Goals } from "../../Components/Tabs/Goals/Goals";
import { Back } from "../../Components/Back/Back";
import InviteMemberModal from "../../Components/Modal/InviteMemberModal";
import {
  useLazyGetProfileQuery,
  useRemoveMemberMutation,
} from "../../../redux/reducers/apiSlice";
import FullPageNexusLogoAnimation from "../../Reusable/FullPageNexusLogoAnimation/FullPageNexusLogoAnimation";
import { useOrganization } from "../../hooks/useOrganization";
import { customConsole } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { Navigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

const OrganizationDetailsPage = () => {
  const [removeMember, { isLoading: loadingRemoveMember }] =
    useRemoveMemberMutation();

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [memberToRemoveId, setMemberToRemoveId] = useState<string | null>(null);
  const navigate = useNavigate();

  const {
    currentOrganization,
    currentOrganizationLoading,
    currentUserRole,
    isCurrentUserAdmin,
    isCurrentUserAMember,
  } = useOrganization();

  const [getProfile, { isLoading: profileDataIsLoading, data: profileData }] =
    useLazyGetProfileQuery();

  useEffect(() => {
    const parsedQueryStrings = queryString.parse(location.search);

    if (parsedQueryStrings?.["inviteModalOpen"] === "true") {
      setIsInviteModalOpen(true);
    } else {
      setIsInviteModalOpen(false);
    }
  }, [location.search]);

  useEffect(() => {
    getProfileFn();
  }, []);

  const getProfileFn = async () => {
    try {
      await getProfile().unwrap();
    } catch (e) {
      Sentry.captureException(e);
      customConsole("log", "Error", e);
    }
  };

  const handlePrimaryButtonClicked = async () => {
    try {
      if (currentOrganization?.id && memberToRemoveId) {
        await removeMember({
          orgId: currentOrganization?.id,
          memberId: memberToRemoveId,
        }).unwrap();
        toast.success(`Successfully removed member`);
      }
    } catch (e) {
      Sentry.captureException(e);
      toast.error(MESSAGE.SYSTEM_ERROR);
    }
    setIsRemoveModalOpen(false);
  };

  if (currentOrganizationLoading || profileDataIsLoading) {
    return <FullPageNexusLogoAnimation />;
  }

  if (currentOrganization?.id && !isCurrentUserAdmin && !isCurrentUserAMember)
    return <Navigate to={`/organizations/${currentOrganization.id}/profile`} />;

  if(!currentOrganization?.id && !currentOrganizationLoading && !profileDataIsLoading){
    return <div>
      <div style={{marginTop: "24px"}}>
        <BackButtonWrapper>
            <Back />
          </BackButtonWrapper>
      </div>
      <p style={{ textAlign: 'center', marginTop: "32px"}}>No organization found for this id.</p>
    </div>
  }

  return (
    <>
      <OrganizationDetailsPageWrapper>
        <BackButtonWrapper>
          <Back />
        </BackButtonWrapper>
        <OrganizationDetailsCard />
        <Tabs<"Members" | "Goals">
          defaultSelectedTab={"Members"}
          tabs={[
            {
              label: "Members",
              component: (
                <Members
                  members={currentOrganization?.members}
                  isAdmin={currentUserRole === "ADMIN"}
                  onRemoveMember={(memberId: string) => {
                    setMemberToRemoveId(memberId);
                    setIsRemoveModalOpen(true);
                  }}
                  onInviteMember={() =>
                    navigate(
                      `/organizations/${currentOrganization?.id}?inviteModalOpen=true`
                    )
                  }
                />
              ),
            },
            {
              label: "Goals",
              component: <Goals organization={currentOrganization} />,
            },
          ]}
        />
        {isRemoveModalOpen && (
          <Modal
            isOpen={isRemoveModalOpen}
            onClose={() => setIsRemoveModalOpen(false)}
            modalText=""
            primaryLoading={loadingRemoveMember}
            disablePrimaryButton={loadingRemoveMember}
            primaryButtonText={"Yes, remove member"}
            secondaryButtonText={"Cancel"}
            modalTitle={"Are you sure you want to remove this member?"}
            onPrimaryButtonClicked={handlePrimaryButtonClicked}
          />
        )}
        {isInviteModalOpen && (
          <InviteMemberModal
            isOpen={isInviteModalOpen}
            onClose={() =>
              navigate(
                `/organizations/${currentOrganization?.id}?inviteModalOpen=false`
              )
            }
          />
        )}
      </OrganizationDetailsPageWrapper>
    </>
  );
};
export default OrganizationDetailsPage;
