import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { api } from "../reducers/apiSlice";
import authReducer from "../reducers/authSlice";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage' 

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ["auth"]
}

const rootReducer = combineReducers({
  "auth": authReducer,
  [api.reducerPath]: api.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
});

export const persistor = persistStore(store)
